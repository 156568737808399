export default {
  success: {
    success: '成功',
    login: '登录成功',
    copy: '复制成功',
    created: '创建成功',
    profitSharingTxSuccess: 'PST 持有人分润成功',
    developerTipTxSuccess: '开发者分润成功',
    sponsorTxSuccess: '赞助成功',
    donateTxSuccess: '打赏成功',
    txSuccess: '交易成功',
    statusPublished: '动态发布成功',
    tickerHoldingUpdateSuccess: '持币状态更新成功'
  },
  failure: {
    login: '登录失败',
    fileFormatError: '文件类型错误',
    gettingAvatarTimeout: '获取头像失败',
    getUsername: '获取用户名失败',
    getAddress: '无法获取钱包地址',
    copy: '复制失败',
    connectionTimedOut: '连接超时，请检查您的网络连接并重试',
    unknownErrorCausedLoadingFailure: '未知错误导致加载失败',
    noPermissionToAccessPage: '你没有权限访问这个页面',
    failedToObtainContractStatus: '无法获取合约状态',
    saveFailed: '保存失败',
    insufficientFunds: '余额不足',
    autoCompressionFail: '图片压缩失败',
    imgVeryBIg: '图片过大',
    unableToGetFile: '无法获取文件',
    profitSharingTxFailed: 'PST 持有人分润失败',
    developerTipTxFailed: '开发者分润失败',
    sponsorTxFailed: '赞助失败',
    donateTxFailed: '打赏失败',
    txFailed: '交易失败',
    txFailMessage: '可能是出现了网络问题或是 Arweave 网关不稳定，请稍后再试',
    contractWriteFailed: '合约调用失败',
    dynamicUploadFailed: '动态上传失败，请检查网络后重试',
    dynamicEncryptFailed: '动态加密失败',
    dynamicEncryptFailedMessage: '动态加密失败，出现了重大严重错误，如果屡次出现请反馈指开发者',
    shouldnotSponsorYourSelf: '不能自己赞助以及打赏自己',
    youCanOnlyPayForYourSelf: '你只能使用与你当前登录地址相同的钱包进行支付',
    getStatusDetails: '无法获取动态详情',
    load: '加载失败',
    download: '下载失败',
    tickerHoldingUpdateFailed: '持币状态更新失败'
  },
  app: {
    loading: '加载中'
  },
  landing: {
    title: '为你的创作建立社区',
    description: 'Likey 提供了来自区块链技术的强大工具，帮助您将您的创作整合在一起，从您的粉丝和感兴趣的人那里获得更多的吸引力，同时将收益管理权还给您。',
    startbtn: '开始浏览'
  },
  login: {
    login: '登录',
    signOut: '登出',
    connectionTimeout: '登录时连接超时，请检查您的网络连接并重试',
    saveYourKeyInCookie: '7 天内使用保存的私钥自动登录',
    insertYourKey: '选择您的钱包密钥文件',
    pleaseInsertYourWalletKey: '请将您的钱包拖到此框中或是点击选中钱包以完成当前操作',
    pleaseLogInFirst: '请先登录'
  },
  pageTitle: {
    myProfile: '我的资料',
    myOrder: '我的订单',
    myStatistic: '管理面板',
    home: '主页',
    themesTest: '主题测试'
  },
  footer: {
    arcucyProducts: 'Arcucy 相关产品',
    followUsStatement: '在上述网站上关注我们以获取最新动态！'
  },
  becomeACreatorBtn: '成为创作者',
  userProfile: {
    noIntroductionYet: '暂无简介',
    flow: '动态',
    sponsorship: '赞助',
    notYetACreator: '还没有成为创作者',
    editUsernameAndAvatar: '编辑用户名和头像',
    changeBanner: '更换横幅图片'
  },
  setting: {
    settings: '设置',
    creatorSetting: '创作设置',
    tokenSettings: '代币设置',
    avatar: '头像',
    avatarSettingDesp: '我们的头像服务来自独立的 Dapp，如需修改请前往',
    username: '用户名',
    usernameSettingDesp: '我们的用户名服务来自独立的 Dapp，如需修改请前往',
    profileAddress: '主页地址',
    canNotBeModified: '设定之后不能修改哦',
    profileAddressPlaceholder: '填入自定义的数字字母组合，5个字符以上',
    introduction: '简介',
    introductionPlaceholder: '一句话介绍你的创作',
    creationCategory: '创作分类',
    creationCategoryPlaceholder: '选择一个分类',
    creationScale: '创作规模',
    creationScalePlaceholder: '选择一个规模',
    nextStep: '下一步',
    previous: '上一步',
    save: '保存',
    personal: '个人',
    team: '团队',
    missingAvatar: '缺少头像',
    pleaseFillInTheProfileAddress: '请填写主页地址',
    profileAddressFormatError: '主页地址应为 5 到 41 位英文字符',
    pleaseSelectACreationCategory: '请选择一个创作分类',
    pleaseSelectACreationScale: '请选择一个创作规模',
    pleaseFillInTheIntroduction: '请填写简介',
    introductionIsTooLong: '简介过长',
    contractAddress: '合约地址',
    inputName: '名称',
    inputNamePlaceholder: '填写自定义的代币名称',
    ticker: '缩写（Ticker）',
    tickerPlaceholder: '填写自定义的代币英文缩写',
    exchangeRatio: '代币兑换比率',
    exchangeRatioDescription: '初初始兑换率用于将用户赞助的 AR 数额转化为代币',
    exchangeRatioExceedsTheLimitation: '兑换比率小数点超出了限制',
    exchangeRatioIsNotAValidNumber: '兑换比率值不是一个有效的数字',
    pleaseFillInTheExchangeRatio: '请输入兑换比率',
    unlockPlan: '解锁方案',
    ownBalance: '持有',
    unlock: '解锁',
    endEdit: '编辑完成',
    addSolution: '增加方案',
    solutionName: '方案名称',
    solutionIntroduction: '方案介绍',
    warning: '提示',
    areYouSureYouWantToDelete: '确定要删除么？',
    ok: '确定',
    cancel: '取消',
    nameShouldNotBeEmpty: '代币名称不能为空',
    tickerShouldNotBeEmpty: '代币缩写不能为空',
    theLengthOfThisNameIsTooLong: '代币名称过长',
    theLengthOfThisTickerSymbolIsTooLong: '代币缩写过长',
    solutionEditingHasNotCompletedYet: '解锁方案尚未完成编辑',
    pleaseReturnToThePreviousStepToFillInTheCreatorForm: '请返回上一步以填写创作者表格',
    createSuccessfulAlertContent: '恭喜你成为创作者，你的信息将在下个区块合并时生效，这大概需要几分钟的时间。',
    submittingPleaseDoNotCloseThePage: '正在提交中，请不要关闭页面',
    usernameCanNotBeEmpty: '请设置你的用户名'
  },
  creationCategoryOptions: {
    painting: '绘画',
    video: '视频',
    writing: '写作',
    game: '游戏',
    music: '音乐',
    podcast: '播客',
    photography: '摄影',
    technology: '技术',
    vtuber: 'Vtuber',
    dance: '舞蹈',
    physicalEducation: '体育',
    tourism: '旅游',
    food: '美食',
    fashion: '时尚',
    digital: '数码',
    animation: '动画',
    translation: '翻译',
    astrology: '星座',
    comics: '漫画',
    art: '艺术',
    popularScience: '科普',
    education: '教育',
    other: '其他'
  },
  statusInput: {
    titlePlaceholder: '标题',
    contentPlaceholder: '正文，输入你想要发表的动态吧～',
    push: '发布动态',
    download: '下载',
    pictureSelectionLimitWarning: '最多只能选择{0}张照片',
    everyoneCanSee: '所有人可见',
    allSponsors: '所有持仓者',
    ownNUnlock: '持有 {0} {1}/解锁',
    uploadingStatus: '正在上传动态'
  },
  sponsor: {
    sponsor: '赞助',
    sponsorStatistics: '赞赏统计',
    totalSupply: '发行总量',
    holders: '持仓人数',
    donationAndSponsorCount: '赞赏总数',
    custom: '自定义',
    customInputDescription: '本方案可自定义 PST 购买数量',
    locked: '已锁定',
    unlocked: '已解锁',
    unlockValueNeed: '还需要 {0} {1}',
    myBalance: '已经持有'
  },
  donation: {
    donateToCreator: '打赏创作者',
    pleaseInputDonationAmount: '请输入打赏金额',
    donationAmountShouldnotBeNone: '打赏金额不能为空',
    pleaseInputValidDonationAmount: '请输入正确的打赏金额'
  },
  payment: {
    pay: '支 付',
    purchase: '购买',
    checkout: '结 算',
    creator: '创作者',
    holder: '代币持有者',
    developer: '开发者',
    fee: '手续费',
    total: '总计',
    txPosted: '交易已经提交至 Arweave 区块链，你可以点击以访问交易：{0}',
    transactionInProgres: '交易正在进行',
    transactionInProgress: '你的交易正在进行，请不要关闭本页面。在此等待期间，你可以继续浏览本页的其他内容',
    currentBalance: '当前余额',
    afterBalance: '结余',
    dataLoadingPleaseTryLater: '数据正在加载中，请稍后重试',
    nextTransactionInProgress: '正在执行下一笔交易'
  },
  progressText: {
    status: '上传动态中',
    media: '上传媒体中',
    audio: '上传音频中',
    file: '上传文件中',
    encrypting: '加密中'
  },
  order: {
    allPurchases: '全部购买',
    sponsors: '赞助',
    donations: '打赏',
    purchasePST: '购买 PST',
    onlyDonation: '打赏作者',
    nodata: '暂无历史订单数据'
  },
  statistics: {
    historyIncome: '历史收入',
    donationCount: '动态赞赏总数',
    sponsorCount: 'PST 赞助总数'
  },
  flowCard: {
    loadMore: '加载全文',
    numPictures: '{0} 张图片',
    audio: '音频',
    file: '附件',
    noStatusYet: '暂无动态',
    ownNUnlock: '持有 {0} {1}/解锁',
    needToMeetTheAboveUnlockConditions: '你需要达成以上解锁条件',
    unlocked: '已解锁',
    displayContent: '显示内容',
    donate: '赞赏',
    share: '分享',
    downloading: '下载中',
    downloaded: '已下载',
    ifYourDownloadDidnotStarted: '如果你的下载没有开始，',
    clickHere: '请点击这里',
    shortnameLoading: '地址加载中...',
    nmaeLoading: '昵称加载中...'
  },
  home: {
    tabFlow: '最新动态',
    tabSponsored: '我赞助的',
    flowLoading: '正在加载动态',
    creatorsLoading: '正在加载创作者',
    showMoreCreators: '显示更多',
    findMoreCreators: '发现创作者',
    sharedLinkHasBeenCopiedToTheClipboard: '已复制分享链接到剪贴板'
  }
}
