export default {
  success: {
    success: 'Success',
    login: 'Login successful',
    copy: 'Copy successfully',
    created: 'Created successfully',
    profitSharingTxSuccess: 'Successfully profit shared to PST holder',
    developerTipTxSuccess: 'Successfully profit shared to developer',
    sponsorTxSuccess: 'Sponsor Successful',
    donateTxSuccess: 'Donation Successful',
    txSuccess: 'Transaction Successful',
    statusPublished: 'Status published successful',
    tickerHoldingUpdateSuccess: 'Holding tickers update successful'
  },
  failure: {
    login: 'Login failed',
    fileFormatError: 'File format error',
    gettingAvatarTimeout: 'Error getting avatar',
    getUsername: 'Failed to get username',
    getAddress: 'Failed to get address',
    copy: 'Copy failed',
    connectionTimedOut: 'Connection timed out, please check your connection and try again.',
    unknownErrorCausedLoadingFailure: 'Unknown error caused loading failure',
    noPermissionToAccessPage: 'You do not have permission to access this page',
    failedToObtainContractStatus: 'Failed to obtain contract status',
    saveFailed: 'Save failed',
    insufficientFunds: 'Insufficient Funds',
    autoCompressionFail: 'Image compression failed',
    imgVeryBIg: 'Image too large',
    unableToGetFile: 'Unable to get file',
    profitSharingTxFailed: 'Failed to Profit Share to PST Holder',
    developerTipTxFailed: 'Failed to Profit Share to Developer',
    sponsorTxFailed: 'Sponsor Failed',
    donateTxFailed: 'Donation Failed',
    txFailed: 'Transaction Failed',
    txFailMessage: 'There may be a network problem or the Arweave gateway unstable issue, please try again later',
    contractWriteFailed: 'Write contract failed',
    dynamicUploadFailed: 'Status upload failed',
    dynamicEncryptFailed: 'Status encryption failed',
    dynamicEncryptFailedMessage: 'Status encryption failed, please contact developers if this is happening often',
    shouldnotSponsorYourSelf: 'Can\'t sponsor or donate yourself',
    youCanOnlyPayForYourSelf: 'You can only pay with the wallet having same address as your current logged in address',
    getStatusDetails: 'Unable to get status details',
    load: 'Failed to load',
    download: 'Failed to download',
    tickerHoldingUpdateFailed: 'Failed to update holding tickers'
  },
  app: {
    loading: 'Loading'
  },
  landing: {
    title: 'Build Your Community For Your Creation',
    description: 'Likey provides powerful tools from blockchain technologies to help integrate your creation together and gain more attractions from your fans and interested people while giving the income management back to you',
    startbtn: 'Start'
  },
  login: {
    login: 'Login',
    signOut: 'Sign Out',
    connectionTimeout: 'Connection time out while logging in, please check your connection and try again.',
    saveYourKeyInCookie: 'Save Key for this Session for 7 days',
    insertYourKey: 'Insert your wallet key',
    pleaseInsertYourWalletKey: 'Please drag your wallet application into this box to complete the action.',
    pleaseLogInFirst: 'Please login first'
  },
  pageTitle: {
    myProfile: 'My Profile',
    myOrder: 'My Orders',
    myStatistic: 'Stats Panel',
    home: 'Home',
    themesTest: 'Themes Test'
  },
  footer: {
    arcucyProducts: 'Arcucy Products',
    followUsStatement: 'Follow us on above wesite to get the newest updates!'
  },
  becomeACreatorBtn: 'Start Creating',
  userProfile: {
    noIntroductionYet: 'No Introduction Yet',
    flow: 'Flow',
    sponsorship: 'Sponsorship',
    notYetACreator: 'Not a creator yet',
    editUsernameAndAvatar: 'Edit username and avatar',
    changeBanner: 'Update Banner'
  },
  setting: {
    settings: 'Settings',
    creatorSetting: 'Creator Settings',
    tokenSettings: 'Token Settings',
    avatar: 'Avatar',
    avatarSettingDesp: 'Our avatar service comes from another Dapp, if you need to modify it, please go to',
    username: 'Username',
    usernameSettingDesp: 'Our username service comes from another Dapp, if you need to modify it, please go to',
    profileAddress: 'Profile Address',
    canNotBeModified: 'Cannot be modified after submission',
    profileAddressPlaceholder: 'Fill in a custom combination of numbers and letters, more than 5 characters',
    introduction: 'Introduction',
    introductionPlaceholder: 'Introduce your creation in one sentence',
    creationCategory: 'Creation Category',
    creationCategoryPlaceholder: 'Choose a category',
    creationScale: 'Creation Scale',
    creationScalePlaceholder: 'Choose a scale',
    nextStep: 'Next Step',
    previous: 'Previous Step',
    save: 'Save',
    personal: 'Personal',
    team: 'Team',
    missingAvatar: 'Missing avatar',
    pleaseFillInTheProfileAddress: 'Please fill in the profile address',
    profileAddressFormatError: 'Profile address length should be within 4 to 41 alphabet characters',
    pleaseSelectACreationCategory: 'Please select a creation category',
    pleaseSelectACreationScale: 'Please select a creation scale',
    pleaseFillInTheIntroduction: 'Please fill in the introduction',
    introductionIsTooLong: 'Introduction is too long',
    contractAddress: 'Contract Address',
    inputName: 'Name',
    inputNamePlaceholder: 'Fill in the custom token name',
    ticker: 'Ticker Symbol',
    tickerPlaceholder: 'Fill in the custom ticker symbol',
    exchangeRatio: 'Exchange Ratio',
    exchangeRatioDescription: 'Initial exchange ratio is used to convert the AR sponsored by the user to token',
    exchangeRatioExceedsTheLimitation: 'Exchang ratio decimals should not beyond the token',
    exchangeRatioIsNotAValidNumber: 'Exchange ratio is not a valid number',
    pleaseFillInTheExchangeRatio: 'Please fill in the exchange ratio',
    unlockPlan: 'Unlock Solution',
    ownBalance: 'Own',
    unlock: 'Unlock',
    endEdit: 'Edit Completed',
    addSolution: 'Add Solution',
    solutionName: 'Solution name',
    solutionIntroduction: 'Solution introduction',
    warning: 'Warning',
    areYouSureYouWantToDelete: 'Are you sure you want to delete?',
    ok: 'OK',
    cancel: 'Cancel',
    nameShouldNotBeEmpty: 'Name shouldn\'t be empty',
    tickerShouldNotBeEmpty: 'Ticker shouldn\'t be empty',
    theLengthOfThisNameIsTooLong: 'Name exceeds the length limit',
    theLengthOfThisTickerSymbolIsTooLong: 'Ticker symbol exceeds the length limit',
    solutionEditingHasNotCompletedYet: 'solution editing hasn\'t completed yet',
    pleaseReturnToThePreviousStepToFillInTheCreatorForm: 'Please return to the previous step to fill in the creator form',
    createSuccessfulAlertContent: 'Congratulations! You just became a creator! Your information will be available after the block been mined, this usually takes minutes to complete.',
    submittingPleaseDoNotCloseThePage: 'Submitting, please do not close the page',
    usernameCanNotBeEmpty: 'Please set your username'
  },
  creationCategoryOptions: {
    painting: 'Painting',
    video: 'Video',
    writing: 'Writing',
    game: 'Game',
    music: 'Music',
    podcast: 'Podcast',
    photography: 'Photography',
    technology: 'Technology',
    vtuber: 'Vtuber',
    dance: 'Dance',
    physicalEducation: 'Physical Education',
    tourism: 'Tourism',
    food: 'Food',
    fashion: 'Fashion',
    digital: 'Digital',
    animation: 'Animation',
    translation: 'Translation',
    astrology: 'Astrology',
    comics: 'Comics',
    art: 'Art',
    popularScience: 'Popular Science',
    education: 'Education',
    other: 'Other'
  },
  statusInput: {
    titlePlaceholder: 'Subject',
    contentPlaceholder: 'Content, type in anything you are willing to share',
    push: 'Push',
    download: 'Download',
    pictureSelectionLimitWarning: 'You can only select {0} image(s)',
    everyoneCanSee: 'Everyone Can See',
    allSponsors: 'All Sponsors',
    ownNUnlock: 'Own {0} {1}/Unlock',
    uploadingStatus: 'Uploading Status'
  },
  sponsor: {
    sponsor: 'Sponsors',
    sponsorStatistics: 'Sponsor Statistics',
    totalSupply: 'Total Supply',
    holders: 'Holders',
    donationAndSponsorCount: 'Purchased',
    custom: 'Custom',
    customInputDescription: 'You can specify you purchase amount here',
    locked: 'Locked',
    unlocked: 'Unlocked',
    unlockValueNeed: '{0} {1} is required',
    myBalance: 'Already Own'
  },
  donation: {
    donateToCreator: 'Donate Creator',
    pleaseInputDonationAmount: 'Donation amount',
    donationAmountShouldnotBeNone: 'Donation amount should not be empty',
    pleaseInputValidDonationAmount: 'Please input a valid amount for donation'
  },
  payment: {
    pay: 'Pay',
    purchase: 'Purchase',
    checkout: 'Checkout',
    creator: 'Creator',
    holder: 'PST Holder',
    developer: 'Developer',
    fee: 'Fee',
    total: 'Total',
    txPosted: 'The transaction has been submitted to the Arweave blockchain. Click to view the transaction: {0}',
    transactionInProgres: 'Transaction in progress',
    transactionInProgress: 'Your transaction is in progress, <strong>please do not close this page</strong>. And feel free to browse this page during this time',
    currentBalance: 'Current balance',
    afterBalance: 'New balance',
    dataLoadingPleaseTryLater: 'Loading data, please try again later',
    nextTransactionInProgress: 'next transaction is in progress'
  },
  progressText: {
    status: 'Uploading status',
    media: 'Uploading media',
    audio: 'Uploading audio',
    file: 'Uploading file',
    encrypting: 'Encrypting'
  },
  order: {
    allPurchases: 'All Purchases',
    sponsors: 'Sponsors',
    donations: 'Donations',
    purchasePST: 'Purchase PST',
    onlyDonation: 'Donate Creator',
    nodata: 'No Order History Yet'
  },
  statistics: {
    historyIncome: 'History Income',
    donationCount: 'Sponsor Count',
    sponsorCount: 'Donation Count'
  },
  flowCard: {
    loadMore: 'Load More',
    numPictures: '{0} Pictures',
    audio: 'Audio',
    file: 'File',
    noStatusYet: 'No status yet',
    ownNUnlock: 'Own {0} {1}/Unlock',
    needToMeetTheAboveUnlockConditions: 'You will need to meet the unlock requirement above',
    unlocked: 'Unlocked',
    displayContent: 'Show Content',
    donate: 'Donate',
    share: 'Share',
    downloading: 'Downloading',
    downloaded: 'Downloaded',
    ifYourDownloadDidnotStarted: 'If your download didn\'t started,',
    clickHere: 'click here',
    shortnameLoading: 'Shortname loading...',
    nmaeLoading: 'Nmae loading...'
  },
  home: {
    // 部分翻译存疑
    tabFlow: 'Flow',
    tabSponsored: 'Sponsored',
    flowLoading: 'Flow loading...',
    creatorsLoading: 'Creators loading...',
    showMoreCreators: 'Show more',
    findMoreCreators: 'Find more creators',
    sharedLinkHasBeenCopiedToTheClipboard: 'Shared link has been copied to the clipboard'
  }
}
